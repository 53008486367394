import React from "react"
import "./Footer.scss"
import { Link, useStaticQuery, graphql } from "gatsby"

const date = new Date()
const year = date.getFullYear()

export default function Footer() {
  const productsQuery = useStaticQuery(graphql`
    query {
      allStrapiProduct {
        edges {
          node {
            Name
            Description
            Slug
          }
        }
      }

      allStrapiService {
        edges {
          node {
            Name
            Description
          }
        }
      }
    }
  `)
  const products = productsQuery.allStrapiProduct.edges
  const services = productsQuery.allStrapiService.edges

  const footerItems = React.useMemo(() => {
    return [
      {
        title: "Products",
        subItems: products || [],
      },

      {
        title: "Services",
        subItems: services || [],
        to: "services",
      },
      {
        title: "Support",
        subItems: [
          {
            node: {
              Name: "Contact us",
              to: "contact",
            },
          },
        ],
      },
    ]
  }, [products, services])

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="container">
          <div className="footer__container-columns columns">
            {footerItems.map((footerItem, index) => (
              <div key={index} className="column">
                <div className="footer__container-columns-column">
                  <div className="footer__container-columns-column-title">
                    {footerItem.title}
                  </div>
                  <ul className="footer__container-columns-column-items">
                    {footerItem.subItems.map(
                      (subItem, index) => {
                        if (subItem.node && subItem.node.Slug) {
                          return (
                            <li key={index}>
                              <Link to={`/products/${subItem.node.Slug}`}>
                                {subItem.node ? subItem.node.Name : ""}
                              </Link>
                            </li>
                          )
                        } else if (subItem.node && subItem.node.to) {
                          return (
                            <li key={index}>
                              <Link to={subItem.node.to}>
                                {subItem.node ? subItem.node.Name : ""}
                              </Link>
                            </li>
                          )
                        } else {
                          return (
                            <li key={index}>
                              <Link to={`/#${footerItem.to}`}>
                                {subItem.node ? subItem.node.Name : ""}
                              </Link>
                            </li>
                          )
                        }
                      }
                      // <li key={index}>
                      //   <Link
                      //     to={`${
                      //       subItem.node
                      //         ? subItem.node.Slug
                      //           ? "/products/" + subItem.node.Slug
                      //           : `/${subItem.node.to}`
                      //         : ""
                      //     }`}
                      //   >
                      //     {subItem.node ? subItem.node.Name : ""}
                      //   </Link>
                      // </li>
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <hr />
          <div className="footer__container-additional-information is-flex">
            <div className="is-flex footer__container-additional-information-items">
              {/* <div className="footer__container-additional-information-item">
                Privacy Policy
              </div>
              <div className="footer__container-additional-information-item">
                Terms and Conditions
              </div> */}
              <div className="footer__container-additional-information-item">
                Copyright © {year} CGC
              </div>
            </div>
            <div className="footer__container-social-media-links is-flex">
              <div className="footer__container-social-media-link">
                <a
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/cloud-gate-consulting-llc/"
                  target="_blank"
                >
                  <img src="/linkedin.svg" alt="linkedin" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
